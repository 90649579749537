import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      images: [],
      about: "test",
    }
  },
  mutations: {
    SET_IMAGES(state, images) {
      state.images = images;
      console.log(state.images)
    },
    SET_ABOUT(state, about) {
      state.about = about;
      console.log(state.about)
    }
  },
  actions: {
    async fetchImages({ commit }) {
      const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTej23kdOcgtuZzcozSUoXYgE8xf7LWuTFjpSByDbpde3xjtOi1uk7EkcgYHYB10CBGPIkVMgvkTqJo/pub?gid=123886228&single=true&output=csv';
      try {
        const response = await fetch(url);
        const csvText = await response.text();
        // Convert CSV text to JSON
        const images = csvText.split('\n').slice(1).map(row => {
          const [image_content, image_link, upper_title, lower_title, subtitle] = row.split(',');
          const final_subtitle = subtitle.replace(/\|/g, ",");
          const final_image_content = 'work/' + image_content;
          return { image_content: final_image_content, image_link, upper_title, lower_title, final_subtitle };
        });
        commit('SET_IMAGES', images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },
    setImages({ commit }, images) {
      commit('SET_IMAGES', images);
    },
    async fetchAbout({ commit }) {
      const url = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTej23kdOcgtuZzcozSUoXYgE8xf7LWuTFjpSByDbpde3xjtOi1uk7EkcgYHYB10CBGPIkVMgvkTqJo/pub?gid=0&single=true&output=csv';
      try {
        const response = await fetch(url);
        const csvText = await response.text();
        // Convert CSV text to JSON
        const content = csvText.split('\n').map(row => {
          const rowContents = row.split(',');
          const folderName = rowContents[0]
          const images = rowContents.splice(1)
          .filter(i => i.trim() !== '')
          .map(i => {
            return folderName + "/" + i
          })
          return images;
        });       
        commit('SET_ABOUT', content);
      } catch (error) {
        console.error('Error fetching about:', error);
      }
    },
    setAbout({ commit }, about) {
      commit('SET_ABOUT', about);
    }
  },
  getters: {
    imagesList: state => state.images,
    about: state => state.about,
  }
})
