<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  created() {
    this.$store.dispatch("fetchImages");
    this.$store.dispatch("fetchAbout");
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.v-container {
  padding: 0px !important;
  margin: 0px !important;
  max-width: 100% !important;
}
/* Define the font */
@font-face {
  font-family: "N27";
  src: url("assets/fonts/N27-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Apply the font */
body {
  font-family: "N27", sans-serif;
}

.router-link {
  color: unset;
  text-decoration: none;
}
.spacer-1 {
  height: clamp(10px, 10vw, 140px);
}
.spacer-1 {
  height: clamp(100px, 20vh, 300px);
}
img {
  display: unset !important;
}
.gallery-item {
  display: inline-block;
  margin-right: 1vw;
}

.gallery-item img {
  height: 35vh;
  width: auto;
}

.gallery-item video {
  height: 35vh;
  width: auto;
}

.gallery-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.v-row {
  margin: 0px !important;
  padding: 0px !important;
}
.gallery-item .v-responsive__sizer {
  padding: 0px !important;
}
.gallery-item-work .v-img__img {
  position: unset !important;
}

.gallery-item-work .v-responsive__sizer {
  padding: 0px !important;
}
.gallery-item .v-img__img {
  position: unset !important;
}

.img {
  display: unset !important;
}
.v-img__placeholder {
  position: sticky !important;
}

.v-img__placeholder,
.v-img__img--preload {
  height: 100% !important;
}

.carousel-container .v-img__img {
  object-fit: contain !important;
  position: unset !important;
  height: 70dvh !important;
}

.carousel-container-mobile .v-img__img {
  object-fit: cover !important;
  position: unset !important;
  max-height: 100% !important;
}

@keyframes slideLeft {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-22400px);
  }
}

.sliding-left {
  animation: slideLeft 200s infinite linear;
}

.carousel-container {
  /* height: 100%; */
  overflow: hidden;
  display: flex;
}

@media (max-width: 600px) {
  .carousel-container {
    /* height: 100%; */
    overflow: hidden;
    display: flex;
    min-height: 42vh !important;
  }

  .carousel-container img {
    min-height: 42vh !important;
    object-fit: cover !important;
  }

  .carousel-container .v-img__img {
    object-fit: contain !important;
    position: unset !important;
    max-height: 100% !important;
    height: unset !important;
  }

  .v-img__placeholder,
  .v-img__img--preload {
    height: 100% !important;
  }
}

.carousel-slider {
  max-width: 54vw !important;
  height: fit-content !important;
}

.carousel-wrapper {
  height: fit-content !important;
  margin-top: 5vh !important;
}

.carousel-text {
  margin-top: 20vh !important;
}
</style>
